const logotext = "Máté";
const meta = {
    title: "Máté Drávucz",
    description: "Postgraduate research student in the UK",
};

const introdata = {
    title: "I’m Máté Drávucz",
    animated: {
        first: "I work as a research engineer",
        second: "I do research in machine learning"
    },
    description: "Currently studying at the University of Warwick as a postgraduate research student focusing on machine learning and causal inference.",
    your_img_url: "https://s3.eu-central-1.amazonaws.com/zsdravucz.hu/IMG_0582.JPG",
};

const dataabout = {
    title: "Brief introducation",
    aboutme: "Currently residing in the UK, but originally I was born and raised in Budapest, Hungary. When I finished high school, I knew I wanted to make a change in my life so I decided to move abroad by myself. I love learning new technologies because I believe that's the closest thing to magic. Since then I have lived in the UK and Ireland and I am not planning to stop anytime soon. The possibility to meet new people from all around the world is the best thing that happened to me. Exploring new places and cultures with an open mind is something that keeps me going.",
};

const educationtimeline = [{
    jobtitle: "MSc. Computer Science",
    where: "University of Warwick",
    date: "2021-2022",
},
{
    jobtitle: "BSc. Computer Science",
    where: "Queen's University Belfast",
    date: "2017-2021",
}
];

const worktimeline = [{
        jobtitle: "Student Demonstrator",
        where: "Queen's University Belfast",
        date: "2021",
    },
    {
        jobtitle: "Software Development Engineer Intern",
        where: "Amazon Web Services",
        date: "2020",
    },
    {
        jobtitle: "QA Engineer",
        where: "2ngaming",
        date: "2019",
    },
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "PyTorch",
        value: 75,
    },
    {
        name: "OpenCV",
        value: 80,
    },
    {
        name: "AWS",
        value: 90,
    }
];

const services = [{
        title: "Data Science",
        description: "I'll extract, analyse and interpret large amounts of data from a range of sources, using algorithmic, data mining, artificial intelligence, machine learning and statistical tools, in order to make it accessible to your business.",
    },
    {
        title: "Blockchain development",
        description: "I'll research, analyze, design, develop, test, and maintain various Blockchain applications. Can also develop application functionality with the help of various programming languages.",
    },
    {
        title: "Game development",
        description: "I can create new AI systems in response to project requirements. Also developing systems to control the simulation and behaviour of non-player entities, and provide tools to allow designers to iterate on those behaviours in a data driven manner.",
    },
];

const socialprofils = {
    github: "https://github.com",
    linkedin: "https://linkedin.com/in/mattdravucz/",
    instagram: "https://instagram.com/dravuczm/"
};
export {
    meta,
    dataabout,
    educationtimeline,
    worktimeline,
    skills,
    services,
    introdata,
    socialprofils,
    logotext,
};